$mainColor: var(--mainColor);
$secondColor: var(--secondColor);
$triColor: var(--triColor);
$quadColor: var(--quadColor);
$backBody: var(--backBody);
$footer: var(--footer);

.LastNews {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  gap: max(15px);
  padding-bottom: 40px;
  padding-top: 40px;
  .new {
    width: 330px;
    display: grid;
    grid-template-areas:
      "a"
      "b"
      "c";
    padding: 10px 10px 20px 10px;
    gap: 10px;
    background-color: $mainColor;
    border-radius: 5px;

    position: relative;
    cursor: pointer;
    &:hover {
      .img {
        filter: saturate(0.9);
      }
      .date {
        background: $triColor;
        color: $mainColor;
        box-shadow: inset 0 0 0 1.75em $triColor;
      }
    }

    .img {
      width: 100%;
      height: 174px;
      grid-area: a;
      filter: saturate(0.3);
      transition: filter 200ms ease-in-out;
    }
    .title {
      font-family: "Verdana", sans-serif;
      display: block;
      grid-area: b;
      padding: 0;
      height: auto;
      color: $triColor;
      font-size: 1.2em;
      font-weight: 500;
      text-align: center;
      // padding-top: 10px;
      background-image: none !important;
      letter-spacing: -0.5px;
      word-spacing: 5px;
      text-shadow: none;
    }
    .date {
      color: $triColor;
      position: absolute;
      top: 20px;
      left: -10px;
      background: $mainColor;
      padding: 3px;
      font-size: 0.7em;
      text-transform: uppercase;
      width: 35px;
      text-align: center;
      border: 2px solid $triColor;
      transition: all 0.2s ease-out;
      line-height: 1.5em;
      > span {
        font-weight: bold;
        font-size: 1.3em;
      }
    }
    .text {
      grid-area: c;
      color: $secondColor;
      height: 110px;
      font-size: 0.9em;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      text-align: justify;
      overflow: hidden;
    }
  }
}
.pagination {
  display: flex;
  flex-direction: row;
  user-select: none;
  li {
    border: 1px solid $backBody;
    height: 30px;
    width: 30px;
    background: $mainColor;
    color: $quadColor;
    text-align: center;
    line-height: 30px;
    font-size: 0.7em;
    cursor: pointer;
    transition: all 200ms ease-in-out;

    &:first-child {
      border-radius: 5px 0 0 5px;
    }
    &:last-child {
      border-radius: 0 5px 5px 0;
    }
    &.actif {
      border: 1px solid $triColor;
      color: $triColor;
      font-size: 0.9em;
    }
    &:hover {
      color: $triColor;
      font-size: 0.9em;
    }
  }
}
