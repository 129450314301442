$mainColor: var(--mainColor);
$secondColor: var(--secondColor);
$triColor: var(--triColor);
$quadColor: var(--quadColor);
$backBody: var(--backBody);
$footer: var(--footer);
$borderRadius: 10px;

#NewsEcho {
  .menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    li {
      vertical-align: middle;
      &.noflux {
        background: rgba(255, 0, 140, 0.24);
      }
      svg {
        margin-right: 10px;
      }
    }
  }
  .Simulateurs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    .sim {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      width: 15vw;
      min-width: 250px;
      height: 150px;
      cursor: pointer;
      position: relative;
      &:hover {
        .back {
          filter: none;
        }
        // filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale");
      }
      .back {
        top: 0;
        right: 0;
        width: 15vw;
        min-width: 250px;
        height: 150px;
        position: absolute;
        filter: brightness(40%) grayscale(20%);
        transition: filter ease-in-out 300ms;
        z-index: 0;
      }
      .name {
        font-size: 1.3em;
        text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
        color: $mainColor;
        z-index: 10;
        text-align: center;
        padding: 0 20px;
      }
    }
  }
  .Thematiques {
    flex-wrap: wrap;
    gap: 20px;

    .thema {
      width: 330px;
      height: 170px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      filter: saturate(0.3);
      transition: all 200ms ease-in-out;
      border-radius: 5px;
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
      cursor: pointer;
      &.noflux {
        .text {
          background-color: red;
        }
      }
      .text {
        background-color: $footer;
        color: $mainColor;
        text-transform: uppercase;
        font-size: 1.2em;
        padding: 10px;
        opacity: 0.8;
        transition: all 200ms ease-in-out;
        border-radius: 0 0 5px 5px;
        letter-spacing: -0.5px;
      }
      &:hover {
        filter: saturate(1);
        .text {
          background-color: $triColor;
        }
      }
    }
    @media only screen and (max-width: 1200px) {
      gap: 10px;
      .thema {
        width: 130px !important;
        height: 80px;
      }
    }
  }
}
