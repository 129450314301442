$mainColor: var(--mainColor);
$secondColor: var(--secondColor);
$triColor: var(--triColor);
$quadColor: var(--quadColor);
$backBody: var(--backBody);
$footer: var(--footer);

.OurValors {
  // margin: 0 0 3vh 0;
  // box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  // border-radius: 10px;
  padding:3vh 15vw;
  background-color: $mainColor;
  .listTab {
    display: flex;
    justify-content: space-around;
    align-items: center;
    // margin-bottom: 2em;

    background-color: $mainColor;
    padding: 1em 1em;
    li {
      width: 100%;
      list-style: none;
      margin: 0;
      text-align: center;
      transition: all 200ms ease-in-out;
      cursor: pointer;
      box-sizing: border-box;
      padding-top: 6px;
      border-bottom: 3px solid $mainColor;
      > span {
        font-size: 2rem;
        img {
          height: 40px;
          margin-right: 2px;
        }
      }
      &.current {
        // background-color: $triColor;
        color: $triColor;
        border-bottom: 3px solid $triColor;
        [data-letter="A"] {
          color: $secondColor;
        }
      }
      &:hover {
        // background-color: $secondColor;
        border-bottom: 3px solid $quadColor;
        // color: $mainColor;
      }
      &:last-of-type {
        margin: 0;
      }
    }
  }
  .currentTab {
    padding: 1em 1em;
    background-color: $mainColor;
    min-height: 250px;
  }
}
