.CarousselHares {
  background-color: var(--mainColor);
  // min-height: 20vh;
  padding: 3vh 15vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .letter {
    display: flex;
    flex-direction: row;
    justify-content: first baseline;
    align-items: flex-end;
    margin-right: 1vw;

    $letters: 1 92px, 2 55px, 3 125px, 4 119px, 5 123px;
    &:hover {
      .complet {
        animation-play-state: running;
      }
    }
    @each $i, $size in $letters {
      &:nth-child(#{$i}) {
        .complet {
          animation: toggle#{$i} 2s #{(($i - 1) * 2) + 1}s ease-in-out forwards;
          &:hover {
            animation-play-state: paused;
          }
        }
      }
      @keyframes toggle#{$i} {
        0% {
          clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%);
          width: 0px;
        }
        20% {
          clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);
          width: $size;
        }

        80% {
          clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);
          width: $size;
        }
        100% {
          clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%);
          width: 0px;
        }
      }
      @keyframes reveal#{$i} {
        0% {
          clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%);
          width: 0px;
        }
        100% {
          clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);
          width: $size;
        }
      }
      @keyframes hide#{$i} {
        0% {
          clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);
          width: $size;
        }
        100% {
          clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%);
          width: 0px;
        }
      }
    }

    &:nth-child(2) {
      color: var(--secondColor) !important;
    }

    .name {
      font-size: 2em;
      // height: 80px;
      // width: 40px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      img {
        max-width: 10vw;
        height: 10vw;
        margin-top: 2.5vw;
      }
      &.A {
        img {
          max-width: 10vw;
          height: 12.5vw;
          margin-top: 0;
        }
      }
    }
    .complet {
      margin-left: 5px;
      font-size: 2em;
      width: 0px;
      color: var(--triColor);
      overflow: hidden;
      &.A {
        color: #000;
      }
    }
  }
}

@keyframes reveal {
  0% {
    clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%);
    // opacity: 0;
    width: 0px;
  }
  20% {
    clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);
    // clip-path: polygon(0% 0%, 0% 100%, 70% 100%, 70% 0%);
    // opacity: 1;
    // width: 0px;
    width: 130px;
  }

  80% {
    clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);
    // opacity: 1;
    width: 130px;
  }
  100% {
    clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%);
    // opacity: 0;
    width: 0px;
  }
}
// clip-path: polygon(0% 0%, 0% 100%, 70% 100%, 70% 0%);
