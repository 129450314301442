header {
  height: 120px;
  padding: 0 15vw;
  background: $mainColor;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
  .btn {
    // color: $mainColor;
  }
  .logo {
    margin-top: 20px;
    margin-bottom: 10px;
    height: calc(100% - 30px);
    width: 15vw;
  }
  .info {
    margin-top: 20px;
    margin-bottom: 10px;
    // background-color: yellow;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    .contact,
    .location {
      display: grid;
      grid-template-areas:
        "a b"
        "a c";
      grid-template-rows: 16px 16px;
      grid-template-columns: 34px 180px;

      gap: 2px 10px;
      a {
        color: $triColor;
        &:hover {
          text-decoration: underline;
        }
      }
      .icn {
        grid-area: a;
      }
      .text1 {
        color: $secondColor;
        grid-area: b;
      }
      .text2 {
        color: $secondColor;
        grid-area: c;
      }
      .text1,
      .text2 {
        letter-spacing: 0px;
        font-size: 0.8em;
        line-height: 16px;
      }
    }
  }
}
