$mainColor: var(--mainColor);
$secondColor: var(--secondColor);
$triColor: var(--triColor);
$quadColor: var(--quadColor);
$backBody: var(--backBody);
$footer: var(--footer);
.FlipHomeServices {
  min-width: 100px;
  width: 420px;
  height: 280px;
  .front {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    .name {
      color: $secondColor;
      padding-bottom: 2rem;
      font-size: 1.8rem;
      font-weight: 700;
    }
  }
  .back {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .name {
      font-size: 1.8em;
      margin-bottom: 10px;
    }
    .text {
      font-size: 0.8em;
      padding: 0 25px;
      text-align: center;
    }
  }
}
