$mainColor: var(--mainColor);
$secondColor: var(--secondColor);
$triColor: var(--triColor);
$quadColor: var(--quadColor);
$backBody: var(--backBody);
$footer: var(--footer);
$borderRadius: 10px;

.Contact {
  p {
    font-size: 0.8em !important;
  }
  .btn {
    margin: {
      left: 0 !important;
      right: 0 !important;
    }
    align-self: flex-end;
    width: 120px;
    background: $mainColor !important;
    color: $triColor !important;
    &:hover {
      font-weight: 700 !important;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    .accept {
      margin-top: 1em;
    }
    .field {
      display: flex;
      flex-direction: column-reverse;
      label {
        margin-top: 1em;
      }
      input,
      textarea {
        margin-top: 0.2em;
        outline: none;
        padding: 0.5em;
        border: none;
        border-bottom: 1px solid $secondColor;
        background-color: $backBody;
        transition: background-color 0.25s 10ms;
        font-family: "Verdana", sans-serif;
        font-size: 1em;
        &.error {
          border-bottom: 1px solid rgba(255, 95, 95, 1);
          background-color: rgba(255, 95, 95, 0.25);
        }
        &:focus {
          background-color: $mainColor;
          border-bottom: 1px solid $triColor;
          // box-shadow: inset 0 3em 0 0 $mainColor;
        }
        &:focus + label {
          color: $triColor;
        }
        &::placeholder {
          color: $quadColor;
        }
      }
      textarea {
        height: 200px;
      }
    }
  }
}
