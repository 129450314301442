$mainColor: var(--mainColor);
$secondColor: var(--secondColor);
$triColor: var(--triColor);
$quadColor: var(--quadColor);
$backBody: var(--backBody);
$footer: var(--footer);

.CalltoActionWhoIam {
  //   background: red;
  //   height: 200px;
  //   padding: 0 !important;
  .moreDetail {
    padding: 5vh 15vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    background-color: $triColor;
    // min-height: 150px;
    .text {
      color: $mainColor;
      font-size: 1.4em;
    }
    .btn {
      margin: 0;
      width: 450px;
      height: auto;
      background: $mainColor;
      color: $secondColor !important;
      line-height: normal;
      // line-break: unset;
      word-wrap: unset;
      &:hover {
        // background: $secondColor;
        // color: $mainColor !important;
        font-weight: 700;
      }
    }
  }
  .moreFar {
    background: $mainColor;
    padding: 5vh 15vw 2vh 15vw;
    h3 {
      text-transform: uppercase;
      text-align: center;
      margin: 0;
    }
    .links {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1vw;
      .link {
        width: 200px;
        height: 200px;
        // background: red;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 400ms ease-in-out;
        &:hover {
          //   background: $backBody;
          transform: scale(1.05);
          .text {
            color: $triColor;
          }
        }
        .text {
          margin: 0;
          padding: 0;
          height: auto;
          margin-top: 1em;
          color: $secondColor;
          text-transform: uppercase;
          font-weight: 500;
        }
      }
    }
  }
}
