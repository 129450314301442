$mainColor: var(--mainColor);
$secondColor: var(--secondColor);
$triColor: var(--triColor);
$quadColor: var(--quadColor);
$backBody: var(--backBody);
$footer: var(--footer);

#Admin {
  background: $footer;
  color: $mainColor;
  max-width: calc(100vw - 2rem);
  min-height: 100vh;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  h1 {
    color: $mainColor;
    font-size: 2rem;
    text-align: center;
  }
  h2 {
    color: $triColor;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    border: {
      bottom: 2px solid $triColor;
    }
    text-align: center;
    width: 100%;
  }
  .nav {
    width: fit-content;
    max-width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;
    .link {
      cursor: pointer;
      padding: 0.5rem 1rem;
      background-color: $quadColor;
      border-radius: 0.5rem;
      &:hover {
        color: $triColor;
      }
    }
  }
  section {
    #Config {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      gap: 1rem;
      .group {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin-top: 3rem;
        gap: 1rem;
        width: 100%;
        h2 {
          position: absolute;
          top: -3rem;
          // text-transform: uppercase;
          // border: {
          //   bottom: 2px solid $triColor;
          // }
          // text-align: center;
          // width: 100%;
        }
        .config {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;
          img {
            width: 100px;
          }
          input[type="file"] {
            display: none;
          }
          label {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            text-transform: capitalize;
          }
        }
      }
    }
    #Pages {
      width: 90vw;
      display: grid;
      grid-template-columns: 200px 1fr;
      gap: 1rem;
      .links {
        width: 200px !important;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 0.5rem;
        .linkPage {
          background-color: $quadColor;
          padding: 0.5rem;
          border-radius: 0.5rem;
          cursor: pointer;
          &:hover {
            background-color: $triColor;
          }
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 1rem;
        label {
          display: grid;
          grid-template-columns: 200px 1fr;
          padding: 0.5rem 0;
          border-bottom: 1px solid $quadColor;
          gap: 0.5rem;
          &:nth-last-child(1) {
            border-bottom: none;
          }
          input,
          textarea {
            width: 300px;
          }
        }
        .CallToActionForm {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          justify-content: center;
          // gap: 0.5rem;
        }
      }
    }
  }

  // overflow: scroll;
}
