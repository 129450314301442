.homeBandeau {
  height: 50vh;
  background-image: url("./homeBandeau.png");
  background-size: 45%;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  font-family: "Patrick Hand SC", cursive;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10vw;
  .right,
  .left {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    width: calc(50% - 10vw / 2);
    color: #fff;
    margin-top: -40px;
    font-size: 3em;
    letter-spacing: -1px;
    opacity: 0;
    font-weight: 500;
    animation: fadeIn 500ms ease-in 1s 1 normal forwards;
  }
  .right {
    text-align: left;
  }
  .left {
    text-align: right;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
  @media only screen and (max-width: 1200px) {
    height: 40vh;
    background-size: 130%;
    .right,
    .left {
      font-size: 1.5em;
      text-align: center;
    }
  }
}
