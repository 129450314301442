footer {
  background-color: $footer;
  color: $mainColor;
  padding: 10px 15vw;
  min-height: 220px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  .logo {
    width: 200px;
    height: 80px;
  }
  .infos {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    font-size: 0.8em;
    p {
      margin-top: 5px;
      &:nth-child(2n) {
        margin-bottom: 10px;
      }
    }
    .socials {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      color: $mainColor;
      .icone {
        width: 26px;
        height: 26px;
        background: $secondColor;
        line-height: 28px;
        text-align: center;
        margin-right: 5px;
        color: $mainColor;
        transition: background 200ms ease-in-out;
        &:hover {
          background: $triColor;
        }
        a {
          color: $mainColor;
          transition: color 200ms ease-in-out;
          &:hover {
            color: $mainColor;
          }
        }
      }
    }
  }
  .newletter {
    a {
      color: $mainColor;
      transition: color 200ms ease-in-out;
      &:hover {
        color: $triColor;
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    padding-bottom: 100px;
  }
}
