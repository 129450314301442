nav {
  background: $mainColor;
  text-transform: uppercase;
  border-top: 1px solid $backBody;
  border-bottom: 1px solid $backBody;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  padding: 0 15vw;
  position: relative;
  z-index: 100;
  max-width: 100vw;
  font-size: 0.9em;
  // position: relative;
  .burgerbutton {
    display: none;
  }
  .openSubMenu {
    display: none;
  }
  .button {
    display: none;
  }
  &.sticky {
    position: fixed;
    top: 0;
    width: calc(100% - 30vw);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    & + .page {
      margin-top: 60px;
    }
    .logo {
      transform: scaleX(1);
      width: 140px;
      margin-right: 50px;
      opacity: 1;
      transition: transform 200ms ease-in, width 200ms ease-in,
        margin-right 200ms ease-in, opacity 500ms 200ms ease-in;
    }
  }
  .logo {
    transform: scaleX(0);
    opacity: 0;
    transform-origin: left;
    height: 60px;
    width: 0;
    margin-right: 0;
    transition: transform 200ms 200ms ease-out, width 200ms 200ms ease-out,
      margin-right 200ms 200ms ease-out, opacity 200ms ease-out;
  }
  .menu {
    position: relative;
    background: $mainColor;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    // line-height: 60px;
    width: 100%;
    li,
    a {
      transition: color 200ms ease-in-out;
      cursor: pointer;
      text-align: center;
      color: $secondColor;
      &:hover {
        color: $triColor;
        a {
          color: $triColor;
        }
      }
      &.active {
        color: $triColor;
        a {
          color: $triColor;
        }
      }
    }
    li {
      margin: 0 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // height: 60px;
      // line-height: 60px;
      position: relative;
      &:hover {
        .subMenu {
          transform: scaleY(1);
        }
      }
      &:first-child {
        margin: 0 20px 0 0;
      }
    }
    .subMenu {
      width: 200px;
      background: $secondColor;
      position: absolute;
      transform: scaleY(0);
      transform-origin: top left;
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      // line-height: 20px;
      transition: transform 300ms ease-in-out;
      margin-left: -20px;
      top: 35px;
      li {
        padding: 0 20px;
        width: calc(100% - 40px);
        margin: 0;
        height: 30px;
        line-height: 30px;
        &:hover {
          a {
            opacity: 1;
          }
        }
        a {
          opacity: 0.8;
          color: $mainColor;
          transition: opacity 300ms ease-in-out;
        }
      }
    }
  }
  .social {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 60px;
    li {
      text-align: center;
      width: 60px;
      border-right: 1px solid $backBody;
      border-left: 1px solid $backBody;
      cursor: pointer;
      &:hover {
        a {
          color: $triColor;
        }
      }
      a {
        color: $backBody;
        transition: color 200ms ease-in-out;
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    position: fixed;
    top: 0px;
    height: 60px;
    padding: 0 10px 0 0;
    width: calc(100vw - 10px);
    border-top: none;
    & + .page {
      margin-top: 60px;
    }
    .button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      min-width: 200px;
      margin-right: 10px;

      .connect {
        font-size: 0.7em;
      }
    }
    .logo {
      display: block;
      width: 80px !important;
    }
    .burgerbutton {
      width: 60px;
      height: 60px;
      color: $backBody;
      display: block;
      line-height: 60px;
      text-align: center;
      font-size: 1.8em;
      // padding: 10px;
      // margin: 10px;
      border-right: 1px solid $backBody;
      // border-left: 1px solid $backBody;
    }
    .openSubMenu {
      display: block;
      height: 30px;
      width: 30px;
      text-align: center;
      &:hover {
        + .subMenu {
          transform: scaleY(1);
        }
      }
    }
    .logo {
      width: 200px;
      transform: scaleX(1);
      opacity: 1;
    }
    .menu {
      // display: none;
      flex-direction: column;
      justify-content: flex-start;
      position: absolute;
      top: 61px;
      transform: scaleY(0);
      transition: transform 300ms ease-in-out;
      transform-origin: top left;
      // margin-top: 60px;
      .subMenu {
        top: 30px;
        right: 0;
      }

      &.active {
        transform: scaleY(1);
        // display: block;
      }
      li {
        height: 30px;
        line-height: 30px;
        // padding: 10px;
        text-align: left;
        width: calc(100% - 20px);
        margin: 0 !important;
      }
    }
    .social {
      display: none;
    }
  }
}
