.page {
  // padding-top: 20px;
  // padding-bottom: 20px;
  min-height: calc(100vh - 422px);
  animation: fadeIn 500ms ease-in-out forwards;
  font-size: 0.9em;
  .separator {
    width: 22px;
    height: 2px;
    background-color: $triColor;
    margin: 10px auto 20px;
  }

  ul {
    li {
      color: $quadColor;
      // margin-bottom: 1em;
      line-height: 1.7em;
      list-style: circle;
      list-style: inside;
      // font-size: 0.9em;
      &:last-of-type {
        margin-bottom: 2em;
      }
    }
  }
  p {
    // font-size: 0.9em;
    color: $quadColor;
    margin-bottom: 2em;
    line-height: 1.7em;
    &.small {
      font-size: 0.9em;
      line-height: 1em;
      margin-bottom: 1em;
      margin: auto;
      // letter-spacing: 5px;
    }
  }
  h1 {
    font-size: 2.2em;
    letter-spacing: -1px;
    font-style: normal;
    font-weight: 700;
  }
  h2 {
    padding: 0 !important;
    font-size: 1.5em;
    text-align: center;
    // text-transform: uppercase;
    color: $secondColor;
    font-weight: 700;
  }
  h3 {
    font-size: 1.5em;
    letter-spacing: 0px;
    font-style: normal;
    margin-bottom: 2em;
    color: $secondColor;
  }
  h4 {
    color: $triColor;
    font-size: 1.2em;
    letter-spacing: 0px;
    margin-bottom: 0.5em;
  }
  h1,
  h2,
  h3,
  h4 {
    &.center {
      text-align: center;
    }
  }
  .legend {
    color: $triColor;
    text-decoration: underline;
    font-weight: 700;
    cursor: help;
    position: relative;
    .helper {
      left: 0;
      position: absolute;
      top: 20px;

      color: $mainColor;
      font-size: 0.8em;
      line-height: 1.2em;
      text-align: left;
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
      width: 300px;
      padding: 0.5em 1em;
      transform: scaleY(0);
      font-weight: 100;
      transition: all 200ms ease-in-out;
      transform-origin: top center;
    }
    &:hover {
      .helper {
        z-index: 1000;
        transform: scaleY(1);
        background: $secondColor;
      }
    }
  }
  .sub-title + .bloc {
    padding-top: 0;
  }
  .bloc {
    padding: 2vh 0;
    margin: 0 15vw;
    text-align: justify;
    line-height: 1.2em;
    background: $mainColor;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    color: $quadColor;
    width: auto;
    // min-height: 45vh;
    & > .bloc {
      border-radius: $borderRadius;
    }
    @media (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .title {
    margin: 0;
    padding: 0 15vw;
    font-size: unset;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-family: "Patrick Hand SC", cursive;
    color: $mainColor;
    background-image: url("./banniere2.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top 60px center;
    background-attachment: fixed;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    // background: $mainColor;
  }
  .sub-title {
    background: $mainColor;
    padding-top: 3rem !important;
  }

  .flip-bloc {
    background-color: transparent;
    min-width: 100px;
    height: 100%;
    // border: 1px solid #f1f1f1;
    perspective: 1000px;
    cursor: pointer;
    .inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: all 500ms ease-in;
      transform-style: preserve-3d;
      .front,
      .back {
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        border-radius: $borderRadius;
      }
      .front {
        background-color: $mainColor;
        color: $triColor;
      }
      .back {
        background-color: lighten(#000, 90);
        color: $footer;
        transform: rotateY(180deg);
        .name {
          font-weight: 700 !important;
        }
      }
    }
    &:hover {
      .inner {
        transition: all 700ms ease-out;
        transform: rotateY(180deg);
      }
    }
  }
  .calltoaction {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 160px;
    &.all {
      background-color: $mainColor;
      color: $triColor;
      border-radius: $borderRadius;
      // border: 2px solid $mainColor;
      min-height: 60px;
      transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
      &:hover {
        background-color: $triColor;
        color: $mainColor;
      }
    }
    .name {
      font-size: 1.4em;
      font-weight: 700;
      text-align: center;
      margin-bottom: 20px;
    }
    .text {
      font-size: 0.9rem;
      text-align: center;
    }
  }
  .noback {
    background: none;
  }
  .reset {
    padding: 1vw;
    margin: 0 !important;
  }
  .center {
    justify-content: center !important;
    text-align: center;
  }
  .spacebetween {
    justify-content: space-between;
  }
  .spacearound {
    justify-content: space-around;
  }
  .large {
    padding: 2vh 15vw;
    margin: 0;
  }
  .all {
    padding: 2vh 2vw;
    margin: 0;
  }
  .invert {
    color: $mainColor;
    background: $secondColor;
  }
  .nomargin {
    margin: 0 !important;
  }
  .marginbottom1em {
    margin-bottom: 1em;
  }
  .margintop1em {
    margin-top: 1em;
  }
  .marginbottom2em {
    margin-bottom: 2em;
  }
  .margintop2em {
    margin-top: 2em;
  }
  .nopadding {
    padding: 0 !important;
  }
  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1vw;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    column-gap: 1vw;
  }
  .reverse {
    flex-direction: row-reverse;
  }
  .wrap {
    flex-wrap: wrap;
    gap: 10px;
  }
  .radial-colored {
    background: radial-gradient(#6da3d7 0%, $triColor 70%);
    color: $mainColor;
  }
  .nobold {
    font-weight: 500 !important;
  }
  .colored {
    background: $triColor;
    color: $mainColor;
  }
  .white {
    background: $mainColor;
  }
  .lightgrey {
    background: $backBody;
  }
  .grey {
    background: $quadColor;
  }
  .strong {
    font-weight: 700;
  }
  .spacebottom {
    padding-bottom: 2em;
  }
  .w100 {
    width: 100px;
    min-width: 100px;
    img {
      width: 100px;
    }
  }
  .w150 {
    width: 150px;
    min-width: 150px;
    img {
      width: 150px;
    }
  }
  .w200 {
    width: 200px;
    min-width: 200px;
    img {
      width: 200px;
    }
  }
  .w300 {
    width: 300px;
    min-width: 300px;
    img {
      width: 300px;
      padding: 1rem;
    }
  }
  .w350 {
    width: 350px;
    min-width: 350px;
    img {
      width: 350px;
    }
  }
  .w400 {
    width: 400px;
    min-width: 400px;
    img {
      width: 400px;
    }
  }
  .w500 {
    width: 500px;
    min-width: 500px;
    img {
      width: 500px;
    }
  }
  .w600 {
    width: 600px;
    min-width: 600px;
  }
  .lilbar {
    position: absolute;
    background: $mainColor;
    width: 30px;
    height: 2px;
    margin: 0 auto;
    left: calc(50% - 15px);
    &.colored {
      background: $footer;
    }
    &.blue {
      background: $triColor;
    }
  }
  .scaleUP {
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
  .imglink {
    width: 150px;
    height: 150px;
    border-radius: 1rem;
    // border: 1px solid $secondColor;

    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
    transition: all 0.25s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
}
