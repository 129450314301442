.MEGVideos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
//   border-radius: 1rem;
//   box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
//   padding: 1rem;
  .listVideo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0.5rem;
    @media (max-width: 800px) {
      flex-direction: row;
      justify-content: center;
    }

    .linkVideo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      gap: 1rem;
      font-weight: 700;
      font-size: 1.2rem;
      cursor: pointer;
      transition: transform 0.2s ease-in-out;
      transform-origin: left center;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
