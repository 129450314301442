$mainColor: var(--mainColor);
$secondColor: var(--secondColor);
$triColor: var(--triColor);
$quadColor: var(--quadColor);
$backBody: var(--backBody);
$footer: var(--footer);
$borderRadius: 10px;

@import "./reset";

@import url("https://fonts.googleapis.com/css2?family=Patrick+Hand+SC&display=swap");
* {
  scroll-behavior: smooth;
  &::-webkit-scrollbar-thumb {
    background-color: none;
    transition: background-color 1s ease-in-out;
    border: 4px solid none;
    background-color: $quadColor;
    &:hover {
      background-color: $triColor;
    }
  }
  &::-webkit-scrollbar {
    width: 14px;
    height: 8px;
    transition: background-color 1s ease-in-out;
    background-color: $backBody;
    &:hover {
      background-color: $mainColor;
    }
  }
}
body {
  background: $backBody;
  color: $secondColor;
  font-family: "Verdana", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  letter-spacing: 0.05em;
  .App {
    &.admin {
      display: none;
    }
  }
  a {
    text-decoration: none;
    color: $mainColor;
    &:hover {
      color: $triColor;
    }
    &:active,
    &:visited {
      color: $secondColor;
    }
  }
  .icon {
    height: 34px;
    width: 34px;
    line-height: 34px;
    font-size: 25px;
    text-align: center;
    color: $quadColor !important;
    &:hover {
      color: $triColor !important;
    }
  }
  .link {
    a {
      color: $quadColor !important;
      &:hover {
        color: $triColor !important;
      }
    }
  }
  .btn {
    display: block;
    background-color: $triColor;
    color: $mainColor !important;
    margin: 20px;
    padding: 10px 25px;
    height: 30px;
    line-height: 30px;
    font-size: 0.9em;
    letter-spacing: -0.5px;
    border-radius: 5px;
    text-align: center;
    text-transform: uppercase;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
    border: 1px solid $triColor;
    cursor: pointer;
    transition: all 500ms ease-in-out;
    &:hover {
      background-color: $mainColor;
      color: $triColor !important;
    }
    &.nomargin {
      margin: 0;
    }
    &.nopadding {
      padding: 0 10px;
    }
    &.small {
      max-width: 200px;
    }

    &.invert {
      background: $mainColor !important;
      color: $triColor !important;
      transition: all 0.5 ease-in-out !important;
      font-weight: 500 !important;
      &:hover {
        font-weight: 700 !important;
      }
    }
  }
  .icn {
    font-size: 1em;
    height: 34px;
    width: 34px;
    line-height: 34px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid $triColor;
    color: $triColor;
    cursor: pointer;
    transition: background-color 200ms ease-in-out,
      color 200ms 100ms ease-in-out;
    &:hover {
      color: $mainColor;
      background-color: $triColor;
      a {
        svg {
          path {
            fill: $mainColor;
          }
        }
      }
    }
    a {
      color: $triColor;
    }
  }

  @import "./header";
  @import "./nav";
  @import "./page";
  @import "./footer";

  #modalSearch {
    background: rgba(10, 10, 10, 0.95);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 200ms ease-in-out;
    transform-origin: top center;
    &[data-open="false"] {
      // display: none;
      transform: scaleY(0);
    }
    .body {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 50px;
      position: relative;
      .close {
        position: absolute;
        top: 5px;
        right: 20px;
        font-size: 1.5em;
        color: #777777;
      }
      input {
        background: rgba(0, 0, 0, 0.5);
        color: rgba(255, 255, 255, 0.75);
        font-size: 3em;
        line-height: 1.2em;
        outline: none;
        border: none;
        width: 100%;
        text-align: center;
        padding: 0 10px 0 10px;
        &:focus {
          border-color: #13719b;
          outline: 0;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 8%),
            0 0 8px rgba(#13719b, 60%);
        }
      }
    }
  }

  .backTop {
    border: 2px solid $mainColor;
    color: $quadColor;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    line-height: 56px;
    text-align: center;
    font-size: 24px;
    cursor: pointer;
    transition: width 10ms 10ms ease-in-out, height 10ms 10ms ease-in-out,
      color 800ms ease-in-out, border 800ms 200ms ease-in-out,
      background-color 200ms ease-in-out, box-shadow 200ms ease-in-out;
    &:hover {
      color: $mainColor;
      background-color: $triColor;
      box-shadow: -5px -2px 5px rgba(0, 0, 0, 0.3);
    }
    &.hide {
      color: rgba(0, 0, 0, 0);
      width: 0;
      height: 0;
      overflow: hidden;
      border: 2px solid rgba(0, 0, 0, 0);
    }
    // padding-bottom: 30px;
  }
  .Waiting {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100vh;
    width: 80vw;
    margin: 0 auto;
    animation: fadeIn 200ms ease-in-out forwards;
    // position: fixed;
    .lds-roller {
      transform: scale(2);
      transform-origin: left center;
      display: block;
      position: fixed;
      width: 200px;
      height: 200px;
    }
    .lds-roller div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px;
    }
    .lds-roller div:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: $mainColor;
      margin: -4px 0 0 -4px;
    }
    .lds-roller div:nth-child(1) {
      animation-delay: -0.036s;
    }
    .lds-roller div:nth-child(1):after {
      top: 63px;
      left: 63px;
    }
    .lds-roller div:nth-child(2) {
      animation-delay: -0.072s;
    }
    .lds-roller div:nth-child(2):after {
      top: 68px;
      left: 56px;
    }
    .lds-roller div:nth-child(3) {
      animation-delay: -0.108s;
    }
    .lds-roller div:nth-child(3):after {
      top: 71px;
      left: 48px;
    }
    .lds-roller div:nth-child(4) {
      animation-delay: -0.144s;
    }
    .lds-roller div:nth-child(4):after {
      top: 72px;
      left: 40px;
    }
    .lds-roller div:nth-child(5) {
      animation-delay: -0.18s;
    }
    .lds-roller div:nth-child(5):after {
      top: 71px;
      left: 32px;
    }
    .lds-roller div:nth-child(6) {
      animation-delay: -0.216s;
    }
    .lds-roller div:nth-child(6):after {
      top: 68px;
      left: 24px;
    }
    .lds-roller div:nth-child(7) {
      animation-delay: -0.252s;
    }
    .lds-roller div:nth-child(7):after {
      top: 63px;
      left: 17px;
    }
    .lds-roller div:nth-child(8) {
      animation-delay: -0.288s;
    }
    .lds-roller div:nth-child(8):after {
      top: 56px;
      left: 12px;
    }
    @keyframes lds-roller {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
